.banner {
  height: 80px;
  background-color: #000;
  display: flex;
  position: relative;

  @screen md {
    height: 60px;
    position: relative;
  }
}

.textContainer {
  flex: 3;
}
